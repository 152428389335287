import { datadogRum } from '@datadog/browser-rum-slim';
import {
  allClientSideFlags,
  clientSideId,
  dogfoodBaseUri,
  dogfoodClientSideEventsUri,
  dogfoodContext,
  dogfoodSendEvents,
  dogfoodStreamUri,
  enableFlagSessionReplay,
  enableFullstoryFlagVarsIntegration,
  frontendDatadogFlagRumTracking,
  frontendVersion,
  secureModeContextHash,
} from '@gonfalon/constants';
import type { GonfalonWindow } from '@gonfalon/types';
import {
  BrowserTelemetry,
  initializeTelemetry as initializeSessionTelemetry,
  SessionReplay,
} from '@launchdarkly/browser-telemetry';
import { initialize, LDClient, LDEvaluationDetail, LDInspection } from 'launchdarkly-js-client-sdk';

declare global {
  interface Window extends GonfalonWindow {}
}

let dogfoodClient: LDClient | undefined;
let browserTelemetry: BrowserTelemetry | undefined;

const evaluatedSessionFlags: { [path: string]: { [flagKey: string]: unknown } } = {};

/**
 * DO NOT USE. For testing purposes only.
 */
export function resetDogfoodClient() {
  dogfoodClient = undefined;
}

function createDogfoodClient() {
  const inspectors: LDInspection[] = [];
  if (frontendDatadogFlagRumTracking()) {
    const ddInspector: LDInspection = {
      type: 'flag-used',
      name: 'dd-inspector',
      method: (key: string, detail: LDEvaluationDetail) => {
        datadogRum.addFeatureFlagEvaluation(key, detail.value);
      },
    };

    inspectors.push(ddInspector);
  }

  if (enableFullstoryFlagVarsIntegration()) {
    const fsInspector: LDInspection = {
      type: 'flag-used',
      name: 'fs-inspector',
      method: (key: string, detail: LDEvaluationDetail) => {
        // getCurrentSessionUrl exists, I swear :P
        // It's attached by fullstory.
        if (window.FS === undefined || typeof window.FS.getCurrentSessionURL !== 'function') {
          return;
        }

        const url = window.FS.getCurrentSessionURL(true);
        if (url === undefined) {
          return;
        }

        evaluatedSessionFlags[url] = {
          ...evaluatedSessionFlags[url],
          [key]: detail.value,
        };

        const arrayOfFlags = Object.keys(evaluatedSessionFlags[url]).map(
          (flagKey) => `${flagKey}=${JSON.stringify(evaluatedSessionFlags[url][flagKey], null, 1)}`,
        );

        // setVars exists, I swear :P
        // It's attached by fullstory.
        // https://help.fullstory.com/hc/en-us/articles/1500004101581-FS-setVars-API-Sending-custom-page-data-to-FullStory
        if (typeof window.FS.setVars === 'function') {
          window.FS.setVars('page', { ldflags: arrayOfFlags });
        }
      },
    };

    inspectors.push(fsInspector);
  }

  if (enableFlagSessionReplay() && process.env.NODE_ENV === 'production') {
    const session = new SessionReplay();
    browserTelemetry = initializeSessionTelemetry({ collectors: [session] });
    inspectors.push(...browserTelemetry.inspectors());
  }

  const client = initialize(clientSideId(), dogfoodContext(), {
    application: {
      id: 'gonfalon-web',
      version: process.env.NODE_ENV === 'production' ? frontendVersion() : undefined,
    },
    baseUrl: dogfoodBaseUri(),
    streamUrl: dogfoodStreamUri(),
    eventsUrl: dogfoodClientSideEventsUri(),
    streaming: false,
    sendEvents: dogfoodSendEvents(),
    sendEventsOnlyForVariation: dogfoodSendEvents(),
    bootstrap: allClientSideFlags(),
    hash: secureModeContextHash(),
    inspectors,
  });

  browserTelemetry?.register(client);

  return client;
}

export function getDogfoodClient() {
  if (!dogfoodClient) {
    dogfoodClient = createDogfoodClient();
  }

  return dogfoodClient;
}
